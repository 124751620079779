import { Img } from "react-image";
import SphereMedium from "../../assets/icons/benefits/sphere-medium.svg";

const Benefits = () => {
  return (
    <>
      <section className="section-benefits">
        <div className="container">
          <div className="content">
            <div className="info-text" data-aos="zoom-in">
              <h1>What Are We Doing?</h1>
              <p>
                We're Progressing with a Methodical Step-by-Step Approach at
                Every Stage!
              </p>
            </div>

            <div className="body">
              <div className="item" data-aos="fade-up">
                <h2>Planning and preparation</h2>
                <div className="line-element"></div>
                <p>
                  We arrange cooperation, assign a project team to you, and
                  together create a list of necessary requirements to achieve
                  goals and KPIs of the ad campaign
                </p>
              </div>

              <div className="item" data-aos="fade-up">
                <h2>Influencers search</h2>
                <div className="line-element"></div>
                <p>
                  We search for influencers that match your criteria, negotiate
                  with them, and come to the best terms for your product ad
                </p>
              </div>

              <div className="item" data-aos="fade-up">
                <h2>Approval</h2>
                <div className="line-element"></div>
                <p>
                  You select preferred influencers to work with, confirm the
                  terms of reference and the publication deadlines, as well as
                  draft ad videos
                </p>
              </div>

              <div className="item" data-aos="fade-up">
                <h2>Contracts and publication</h2>
                <div className="line-element"></div>
                <p>
                  We handle all of the legal paperwork with selected influencers
                  and control launch timing and compliance with the approved
                  draft
                </p>
              </div>

              <div className="item" data-aos="fade-up">
                <h2>Reports and payments</h2>
                <div className="line-element"></div>
                <p>
                  We only use one account for payment and offer secure payment
                  option. We are regulated by the state and we pay taxes.
                </p>
              </div>

              <div className="item" data-aos="fade-up">
                <h2>Performance Analysis</h2>
                <div className="line-element"></div>
                <p>
                  We analyze the campaign results, gather feedback, and provide
                  detailed reports on performance metrics and KPIs. Our insights
                  help you understand the campaign’s impact and plan for future
                  improvements.
                </p>
              </div>
            </div>

            <div className="sphere-medium-container">
              <Img src={SphereMedium} alt="sphere-medium" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Benefits;