import { Img } from "react-image";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchSettings } from "../../services/settingsService";
import { fetchSocialNetwork } from "../../services/socialNetworkService";
import { CircleLoader } from "react-spinners";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const [settings, setSettings] = useState({});
  const [loadingSettings, setLoadingSettings] = useState(false);
  const [errorSettings, setErrorSettings] = useState(null);

  const [socialNetwork, setSocialNetwork] = useState([]);
  const [loadingSocialNetwork, setLoadingSocialNetwork] = useState(false);
  const [errorSocialNetwork, setErrorSocialNetwork] = useState(null);

  useEffect(() => {
    setLoadingSettings(true);
    fetchSettings()
      .then((response) => {
        setSettings(response.data.data);
        setLoadingSettings(false);
      })
      .catch((error) => {
        setErrorSettings("An error occurred while fetching settings");
        setLoadingSettings(false);
      });
  }, []);

  useEffect(() => {
    setLoadingSocialNetwork(true);
    fetchSocialNetwork()
      .then((response) => {
        const socialNetworks =
          response.data.data.find((item) => item.social_links)?.social_links ||
          [];
        setSocialNetwork(socialNetworks);
        setLoadingSocialNetwork(false);
      })
      .catch((error) => {
        setErrorSocialNetwork(
          "An error occurred while fetching social network data"
        );
        setLoadingSocialNetwork(false);
      });
  }, []);

  if (loadingSettings || loadingSocialNetwork)
    return (
      <div className="loader-container">
        <CircleLoader color={"#699158"} loading={loadingSocialNetwork} />
      </div>
    );
  if (errorSettings || errorSocialNetwork)
    return <div>Error: {errorSocialNetwork}</div>;

  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="footer-info">
            <Img className="main-logo" src={settings["footer_logo"]} alt="footer_logo" />
            <p className="footer-short-info">
              {settings["footer_description"]}
            </p>

            <div className="footer-social-network">
              {Array.isArray(socialNetwork) &&
                socialNetwork.map((socialNetworkItem) => (
                  <Link key={socialNetworkItem.id} to={socialNetworkItem.link}>
                    <Img src={socialNetworkItem.icon} alt={socialNetworkItem.name} />
                  </Link>
                ))}
            </div>
          </div>

          <div className="footer-nav">
            <h2>Sitemap</h2>
            <ul>
            <li>
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-container-item"
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/services"
                activeClassName="active"
                className="nav-container-item"
              >
                Services
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/influencer"
                activeClassName="active"
                className="nav-container-item"
              >
                Influencers
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                activeClassName="active"
                className="nav-container-item"
              >
                About
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                activeClassName="active"
                className="nav-container-item"
              >
                Contact
              </NavLink>
            </li>
          </ul>
          </div>
        </div>

        <div className="copyright-area">
          <p>
            © <span id="current-year-container">{currentYear}</span> Amir
            Ismayılov. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
