import { Img } from "react-image";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchService } from "../../services/servicesService";
import { CircleLoader } from "react-spinners";
import { Helmet } from "react-helmet";
import Amount from "../../сomponents/sections/Amount";
import GetStartedButton from "../../assets/icons/main/get-started.svg";
import SphereBig from "../../../src/assets/icons/benefits/sphere-medium.svg";
import SphereSmall from "../../../src/assets/icons/hero/sphere-small.svg";

const Services = () => {
  const [services, setServices] = useState([]);
  const [loadingServices, setLoadingServices] = useState(true);
  const [errorServices, setErrorServices] = useState(null);

  useEffect(() => {
    fetchService()
      .then((response) => {
        setServices(response.data.data);
        setLoadingServices(false);
      })
      .catch((error) => {
        setErrorServices("An error occurred while fetching services");
        setLoadingServices(false);
      });
  }, []);

  if (loadingServices) {
    return (
      <div className="loader-container">
        <CircleLoader color="#699158" loading={loadingServices} />
      </div>
    );
  }

  if (errorServices) {
    return (
      <div className="error-container">
        <h2>Error: {errorServices}</h2>
        <p>
          The requested services could not be loaded. Please try again later.
        </p>
        <Link to="/">Return to Home</Link>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{"CryptoWhite | Services" || "Title"}</title>
        <meta name="description" content={"services page" || "Description"} />
        <meta name="keywords" content={"services page" || "Keywords"} />
      </Helmet>

      <section className="services-page">
        <div className="sphere-big-container">
          <Img src={SphereBig} alt="sphere-big" />
        </div>

        <div className="sphere-small-container">
          <Img src={SphereSmall} alt="sphere-small" />
        </div>

        <div className="container">
          <div className="title-container" data-aos="zoom-in">
            <h1>Elevate Your Brand with Our Promotion Services!</h1>
            <p>
              From strategic planning to targeted campaigns, we specialize in
              enhancing your presence in the crypto market. Let's achieve your
              marketing goals together!
            </p>
          </div>
        </div>

        <div className="services-page-body">
          <div className="container">
            <div className="main-content">
              {Array.isArray(services) &&
                services.map((service) => (
                  <div
                    className="service-card"
                    data-aos="fade-up"
                    key={service?.id}
                  >
                    {service.image.length > 1 ? (
                      void ""
                    ) : (
                      <i className={service.icon_class}></i>
                    )}
                    {service.image.length > 1 ? (
                      <Img src={service?.image} alt={service?.title} />
                    ) : (
                      <span></span>
                    )}
                    <h2>{service?.title}</h2>

                    <div
                      dangerouslySetInnerHTML={{ __html: service?.content }}
                    ></div>

                    <Link to={"/contact"}>
                      <span>Get Started</span>
                      <Img src={GetStartedButton} alt="get-stabuttonrted-" />
                    </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <Amount />
      </section>
    </>
  );
};

export default Services;
