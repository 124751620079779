import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { CircleLoader } from "react-spinners";
import { NavLink } from "react-router-dom";
import { Img } from "react-image";
import { fetchSettings } from "../../services/settingsService";
import telegramIcon from "../../assets/icons/main/telegram-icon.svg";
import BtnHamburger from "../../assets/icons/main/btn-hamburger.svg";
import BtnHamburgerClose from "../../assets/icons/main/btn-hamburger-close.svg";

const Header = () => {
  const { pathname } = useLocation();
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [settings, setSettings] = useState([]);
  const [loadingSettings, setLoadingSettings] = useState(false);
  const [errorSettings, setErrorSettings] = useState(null);

  useEffect(() => {
    setLoadingSettings(true);
    fetchSettings()
      .then((response) => {
        setSettings(response.data.data);
        setLoadingSettings(false);
      })
      .catch((error) => {
        setErrorSettings("An error occurred while fetching settings");
        setLoadingSettings(false);
      });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY >= 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (loadingSettings) {
    return (
      <div className="loader-container">
        <CircleLoader color="#699158" loading={loadingSettings} />
      </div>
    );
  }

  if (errorSettings) {
    return <div>Error: {errorSettings}</div>;
  }

  return (
    <header className={`header ${isScrolled ? "fixed" : ""} ${
        pathname === "/influencer" ||
        pathname === "/services" ||
        pathname === "/404"
          ? "black"
          : ""
      }`}
      id={showSideMenu ? "show" : ""}
    >
      <div className="header-container container">
        <div className="mobile-btn-open">
          <Img
            onClick={() => setShowSideMenu(!showSideMenu)}
            src={BtnHamburger}
            alt="btn-hamburger"
          />
        </div>

        <Link to="/" className="logo-container">
          <Img src={settings["logo"]} alt="main-logo" />
        </Link>

        <nav className="nav-container">
          <ul>
            <li>
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-container-item"
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/services"
                activeClassName="active"
                className="nav-container-item"
              >
                Services
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/influencer"
                activeClassName="active"
                className="nav-container-item"
              >
                Influencers
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                activeClassName="active"
                className="nav-container-item"
              >
                About
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                activeClassName="active"
                className="nav-container-item"
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </nav>

        <div className="contact-container mobile_hidden">
          <Link to={settings["telegram_link"]} target="blank">
            <Img src={telegramIcon} alt="telegram-icon" />
          </Link>
        </div>
      </div>

      <div className="sidebar">
        <div className="sidebar-header" onClick={() => setShowSideMenu(false)}>
          <span className="close-button">
            <Img src={BtnHamburgerClose} alt="btn-close"/>
          </span>
        </div>

        <ul className="sidebar-body">
          <li>
            <NavLink
              exact
              to="/"
              activeClassName="active"
              onClick={() => setShowSideMenu(false)}
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/services"
              activeClassName="active"
              onClick={() => setShowSideMenu(false)}
            >
              Services
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/influencer"
              activeClassName="active"
              onClick={() => setShowSideMenu(false)}
            >
              Influencer
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/about"
              activeClassName="active"
              onClick={() => setShowSideMenu(false)}
            >
              About
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact"
              activeClassName="active"
              onClick={() => setShowSideMenu(false)}
            >
              Contact
            </NavLink>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;