import { Img } from "react-image";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import SphereMedium from "../../assets/icons/benefits/sphere-medium.svg";

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>{"CryptoWhite | 404" || "Title"}</title>
        <meta name="description" content={"404 page" || "Description"} />
        <meta name="keywords" content={"404 page" || "Keywords"} />
      </Helmet>

      <section className="section-not-found">
        <div className="container">
          <div className="error-body">
            <div className="image-container">
              <div className="sphere-medium-container">
                <Img src={SphereMedium} alt="sphere-medium" />
              </div>
            </div>

            <h2>Oops, you’ve lost in space </h2>
            <p>We can’t find the page that you’re looking for</p>
            <Link className="btn-return" to="/">
              Go home
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFound;
