import { Img } from "react-image";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { fetchSocialNetwork } from "../../services/socialNetworkService";
import { CircleLoader } from "react-spinners";

const CryptoAgents = () => {
  const [socialNetwork, setSocialNetwork] = useState([]);
  const [loadingSocialNetwork, setLoadingSocialNetwork] = useState(false);
  const [errorSocialNetwork, setErrorSocialNetwork] = useState(null);

  useEffect(() => {
    setLoadingSocialNetwork(true);
    fetchSocialNetwork()
      .then((response) => {
        const telegramAgents =
          response.data.data.find((item) => item.telegram_agents)
            ?.telegram_agents || [];
        setSocialNetwork(telegramAgents);
        setLoadingSocialNetwork(false);
      })
      .catch((error) => {
        setErrorSocialNetwork(
          "An error occurred while fetching social network data"
        );
        setLoadingSocialNetwork(false);
      });
  }, []);

  if (loadingSocialNetwork) {
    return (
      <div className="loader-container">
        <CircleLoader color={"#699158"} loading={loadingSocialNetwork} />
      </div>
    );
  }

  if (errorSocialNetwork) {
    return <div>Error: {errorSocialNetwork}</div>;
  }

  return (
    <>
      <div className="telegram-agents-contacts" >
        <div className="agents-contacts">
          {Array.isArray(socialNetwork) &&
            socialNetwork.map((socialNetworkItem) => (
              <div key={socialNetworkItem.id} className="agents-contacts-item">
                <Link to={socialNetworkItem.link}>
                  <div className="telegram-image-container">
                    <Img
                      src={socialNetworkItem.icon}
                      alt={socialNetworkItem.name}
                    />
                  </div>
                  <span>{socialNetworkItem.name}</span>
                </Link>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default CryptoAgents;