import { Img } from "react-image";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
  fetchInfluencerStatistics,
  fetchInfluencers,
} from "../../services/influencersService";
import { CircleLoader } from "react-spinners";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectCoverflow } from "swiper/modules";
import SphereBig from "../../../src/assets/icons/benefits/sphere-medium.svg";
import SphereSmall from "../../../src/assets/icons/hero/sphere-small.svg";

function Statistics() {
  const [influencers, setInfluencers] = useState([]);
  const [newObjectsWithoutInternal, setNewObjectsWithoutInternal] = useState(
    []
  );
  const [loadingInfluencers, setLoadingInfluencers] = useState(true);
  const [errorInfluencers, setErrorInfluencers] = useState(null);

  const { id } = useParams();
  const [influencerStatistics, setInfluencerStatistics] = useState({});
  const [loadingInfluencerStatistics, setLoadingInfluencerStatistics] =
    useState(true);
  const [errorInfluencerStatistics, setErrorInfluencerStatistics] =
    useState(null);

  useEffect(() => {
    setLoadingInfluencers(true);
    fetchInfluencers()
      .then((response) => {
        const fetchedInfluencers = response.data.data;
        setInfluencers(fetchedInfluencers);
        const filteredInfluencers = fetchedInfluencers.filter(
          (item) => item.id !== parseInt(id)
        );
        setNewObjectsWithoutInternal(filteredInfluencers);
        setLoadingInfluencers(false);
      })
      .catch(() => {
        setErrorInfluencers("An error occurred while fetching influencers");
        setLoadingInfluencers(false);
      });
  }, [id]);

  useEffect(() => {
    setLoadingInfluencerStatistics(true);
    fetchInfluencerStatistics(id)
      .then((response) => {
        setInfluencerStatistics(response.data.data);
        setLoadingInfluencerStatistics(false);
      })
      .catch(() => {
        setErrorInfluencerStatistics(
          "An error occurred while fetching statistics"
        );
        setLoadingInfluencerStatistics(false);
      });
  }, [id]);

  if (loadingInfluencerStatistics || loadingInfluencers) {
    return (
      <div className="loader-container">
        <CircleLoader
          color="#699158"
          loading={loadingInfluencerStatistics || loadingInfluencers}
        />
      </div>
    );
  }

  if (errorInfluencerStatistics || errorInfluencers) {
    return <div>Error: {errorInfluencerStatistics || errorInfluencers}</div>;
  }

  return (
    <>
      <Helmet>
        <title>
          {`CryptoWhite | ${influencerStatistics?.name}` || "Title"}
        </title>
        <meta
          name="description"
          content={"influencers page" || "Description"}
        />
        <meta name="keywords" content={"influencers page" || "Keywords"} />
      </Helmet>

      <section className="statistics-page">
        <div className="container">
          <div className="title-container" data-aos="zoom-in">
            <h1>Crypto Agency Statistics</h1>
            <p>
              Explore key insights and trends in the cryptocurrency market with
              our statistics page.
            </p>
          </div>

          <div className="main-content">
            <div className="sphere-big-container">
              <Img src={SphereBig} alt="sphere-big" />
            </div>

            <div className="sphere-small-container">
              <Img src={SphereSmall} alt="sphere-small" />
            </div>
            <div className="main-content-left" data-aos="fade-right">
              <div key={influencerStatistics?.id} className="influencer-item">
                <div
                  className="influencer-item-image"
                  style={{
                    backgroundImage: `url(${influencerStatistics?.image})`,
                  }}
                ></div>
                <div className="influencer-item-content">
                  <p className="name">{influencerStatistics?.name}</p>
                  <div className="count">
                    <span>{influencerStatistics?.sub_count}</span>
                    <p>Subscribers</p>
                  </div>
                  <div className="buttons">
                    <Link
                      to={influencerStatistics?.channel_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Go to channel
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="main-content-right" data-aos="fade-left">
              <div className="statistics-wrapper">
                <div className="statistics-contents">
                  <ul>
                    {influencerStatistics?.influencer_country_statistics
                      ?.length > 0 ? (
                      influencerStatistics.influencer_country_statistics.map(
                        (stats) => (
                          <li key={stats.id}>
                            <div className="country-wrapper">
                              <Img
                                src={stats.country.image}
                                alt={stats.country.name}
                              />
                              <span>{stats.country.name}</span>
                            </div>
                            <p>%{Math.floor(stats.percent)}</p>
                          </li>
                        )
                      )
                    ) : (
                      <p>No data available 🤖</p>
                    )}
                  </ul>
                </div>

                <div className="statistics-contents">
                  <ul>
                    {influencerStatistics?.influencer_age_statistics?.length >
                    0 ? (
                      influencerStatistics.influencer_age_statistics.map(
                        (stats) => (
                          <li key={stats.id}>
                            <span>{stats.name}</span>
                            <p>%{Math.floor(stats.percent)}</p>
                          </li>
                        )
                      )
                    ) : (
                      <p>No data available 👾</p>
                    )}
                  </ul>
                </div>

                <div className="statistics-contents">
                  <ul>
                    {influencerStatistics?.influencer_gender_statistics
                      ?.length > 0 ? (
                      influencerStatistics.influencer_gender_statistics.map(
                        (stats) => (
                          <li key={stats.id}>
                            <span>{stats.name}</span>
                            <p>%{Math.floor(stats.percent)}</p>
                          </li>
                        )
                      )
                    ) : (
                      <p>No data available 👻</p>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="other-influencers-body">
          <div className="container">
            <div className="title-container" data-aos="zoom-in">
              <h1>Our other influencers</h1>
            </div>
            <Swiper
              className="swiper-container"
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={1}
              loop={true}
              spaceBetween={60}
              effect="EffectFade"
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
              }}
              breakpoints={{
                520: { slidesPerView: 2, spaceBetween: 20 },
                768: { slidesPerView: 2, spaceBetween: 30 },
                1024: { slidesPerView: 3, spaceBetween: 30 },
              }}
              modules={[EffectCoverflow, Navigation]}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
            >
              {newObjectsWithoutInternal.map((influencer) => (
                <SwiperSlide key={influencer?.id}>
                  <div className="other-influencers-wrapper-item">
                    <div
                      className="other-influencers-wrapper-item-image"
                      style={{
                        backgroundImage: `url(${influencer?.image})`,
                      }}
                    ></div>
                    <div className="other-influencers-wrapper-item-content">
                      <p className="name">{influencer?.name}</p>
                      <div className="count">
                        <span>{influencer?.sub_count}</span>
                        <p>Subscribers</p>
                      </div>
                      <div className="buttons">
                        <Link to={`/influencer/${influencer?.id}`}>
                          Statistic
                        </Link>
                        <Link
                          to={influencer?.channel_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Go to channel
                        </Link>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
              <div className="swiper-button-prev"></div>
              <div className="swiper-button-next"></div>
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
}

export default Statistics;
