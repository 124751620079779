import { Img } from "react-image";
import { useEffect, useState } from "react";
import { fetchStatistics} from "../../services/statisticsService";
import { CircleLoader } from "react-spinners";
import CountUp from "react-countup";
import SphereSmall from "../../../src/assets/icons/hero/sphere-small.svg";
import SphereMedium from "../../assets/icons/benefits/sphere-medium.svg";

const AmountMain = () => {
  const [statistics, setStatistics] = useState({});
  const [loadingStatistics, setLoadingStatistics] = useState(false);
  const [errorStatistics, setErrorStatistics] = useState(null);

  useEffect(() => {
    setLoadingStatistics(true);
    fetchStatistics()
      .then((response) => {
        setStatistics(response.data.data);
        setLoadingStatistics(false);
      })
      .catch((error) => {
        setErrorStatistics("An error occurred while fetching settings");
        setLoadingStatistics(false);
      });
  }, []);

  if (loadingStatistics) {
    return (
      <div className="loader-container">
        <CircleLoader color={"#699158"} loading={loadingStatistics} />
      </div>
    );
  }

  if (errorStatistics) {
    return <div>Error: {errorStatistics}</div>;
  }

  return (
    <>
      <section className="section-amount-main">
        <div className="container">
          <div className="content">
            <div className="content-item" data-aos="fade-right">
              <p>
                <CountUp end={statistics["videos-released-monthly"]} duration={5} />
                <span>+</span>
              </p>
              <p>Videos released monthly</p>
            </div>

            <div className="content-item" data-aos="fade-left">
              <p>
                <CountUp end={statistics["projects-worked-with-us"]} duration={5} />
                <span>+</span>
              </p>
              <p>Projects worked with us</p>
            </div>
          </div>

          <div className="sphere-small-container">
            <Img src={SphereSmall} alt="sphere-small" />
          </div>

          <div className="sphere-medium-container">
            <Img src={SphereMedium} alt="sphere-medium" />
          </div>
        </div>
      </section>
    </>
  );
};

export default AmountMain;
