import { Img } from "react-image";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { postDataFromContact } from "../../services/contactPostService";
import { fetchSettings } from "../../services/settingsService";
import { CircleLoader } from "react-spinners";
import { Helmet } from "react-helmet";
import CryptoAgents from "../../сomponents/sections/CryptoAgents";
import SphereBig from "../../../src/assets/icons/hero/sphere-big.svg";
import SphereSmall from "../../../src/assets/icons/hero/sphere-small.svg";
import ElementImage from "../../../src/assets/icons/hero/element.svg";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const [settings, setSettings] = useState({});
  const [loadingSettings, setLoadingSettings] = useState(false);
  const [errorSettings, setErrorSettings] = useState(null);

  useEffect(() => {
    setLoadingSettings(true);
    fetchSettings()
      .then((response) => {
        setSettings(response.data.data);
        setLoadingSettings(false);
      })
      .catch((error) => {
        setErrorSettings("An error occurred while fetching settings");
        setLoadingSettings(false);
      });
  }, []);

  if (loadingSettings) {
    return (
      <div className="loader-container">
        <CircleLoader color={"#699158"} loading={loadingSettings} />
      </div>
    );
  }

  if (errorSettings) {
    return <div>Error: {errorSettings}</div>;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = {
      name: event.target.elements.namedItem("name").value,
      email: event.target.elements.namedItem("email").value,
      message: event.target.elements.namedItem("message").value,
    };

    const validateForm = ({ name, email, message }) => {
      if (!name.trim() || !email.trim() || !message.trim()) {
        toast.error("Please fill in all fields.");
        return false;
      }
      if (!/\S+@\S+\.\S+/.test(email)) {
        toast.error("Email is invalid.");
        return false;
      }
      return true;
    };

    if (!validateForm(formData)) return;

    try {
      const response = await postDataFromContact(formData);
      toast.success("Message sent successfully.");
      event.target.reset();
    } catch (error) {
      toast.error("Failed to send message.");
    }
  };

  return (
    <>
      <Helmet>
        <title>{"CryptoWhite | Contacts" || "Title"}</title>
        <meta name="description" content={"contacts page" || "Description"} />
        <meta name="keywords" content={"contacts page" || "Keywords"} />
      </Helmet>

      <ToastContainer
        position="bottom-right"
        autoClose={4000}
        hideProgressBar={true}
      />

      <section className="contact-page">
        <div className="sphere-big-container">
          <Img src={SphereBig} alt="sphere-big" />
        </div>

        <div className="sphere-small-container">
          <Img src={SphereSmall} alt="sphere-small" />
        </div>

        <div className="element-container">
          <Img src={ElementImage} alt="element" />
        </div>

        <div className="container">
          <div className="title-container" data-aos="zoom-in">
            <h1>Contact us with fill form</h1>
            <p>
              Reach out for insights, consultations, or collaborations in the
              crypto!
            </p>
          </div>

          <div className="contact-form">
            <form onSubmit={handleSubmit}>
              <div className="fields-group">
                <div className="form-group">
                  <div className="input-item">
                    <label htmlFor="name">
                      Your name
                      <span>*</span>
                    </label>
                    <input name="name" type="text" />
                  </div>

                  <div className="input-item">
                    <label htmlFor="">
                      Your email<span>*</span>
                    </label>
                    <input name="email" type="email" />
                  </div>
                </div>

                <div className="message">
                  <div className="input-item">
                    <label htmlFor="">
                      Your message <span>*</span>
                    </label>
                    <textarea
                      cols="30"
                      rows="14"
                      name="message"
                      className="opinnion"
                    ></textarea>
                  </div>
                </div>
              </div>

              <input type="submit" className="submit" value="Send" />
            </form>
          </div>
        </div>
        <CryptoAgents agentsContacts={settings} />
      </section>
    </>
  );
};

export default Contact;