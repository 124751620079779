const Solutions = () => {
  return (
    <>
      <section className="section-information">
        <div className="container">
          <div className="content">
            <div className="content-main">
              <div data-aos="zoom-in">
                <h2>
                  We provide full range <br /> marketing solutions in
                  crypto-market
                </h2>
              </div>

              <ul>
                <li data-aos="fade-right">
                  <div className="solution-info">
                    <span>Suitable price</span>
                    <p>Affordable, Tailored Pricing High-Quality Marketing</p>
                  </div>
                </li>

                <li data-aos="fade-right">
                  <div className="solution-info">
                    <span>Live support</span>
                    <p>
                      Our dedicated team is here in real-time, ensuring your{" "}
                    </p>
                  </div>
                </li>

                <li data-aos="fade-right">
                  <div className="solution-info">
                    <span>Transparency</span>
                    <p>Clear, step-by-step performance reports.</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Solutions;
