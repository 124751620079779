import React from "react";
import CountUp from "react-countup";
import { Img } from "react-image";
import { useEffect, useState } from "react";
import { fetchStatistics} from "../../services/statisticsService";
import { CircleLoader } from "react-spinners";
import SphereBig from "../../assets/icons/services/sphere-big.svg";
import SphereSmall from "../../assets/icons/services/sphere-small.svg";

const Amount = () => {
  const [statistics, setStatistics] = useState({});
  const [loadingStatistics, setLoadingStatistics] = useState(false);
  const [errorStatistics, setErrorStatistics] = useState(null);

  useEffect(() => {
    setLoadingStatistics(true);
    fetchStatistics()
      .then((response) => {
        setStatistics(response.data.data);
        setLoadingStatistics(false);
      })
      .catch((error) => {
        setErrorStatistics("An error occurred while fetching settings");
        setLoadingStatistics(false);
      });
  }, []);

  if (loadingStatistics) {
    return (
      <div className="loader-container">
        <CircleLoader color={"#699158"} loading={loadingStatistics} />
      </div>
    );
  }

  if (errorStatistics) {
    return <div>Error: {errorStatistics}</div>;
  }
  return (
    <div className="services-amount-body">
      <div className="container">
        <div className="services-amount-wrapper" data-aos="fade-up">
          <div className="item-container">
            <h3>
              <CountUp end={statistics["daily-video"]} duration={5} />
              <p>+</p>
            </h3>
            <p>Daily Video</p>
          </div>

          <div className="item-container">
            <h3>
              <CountUp end={statistics["satisfied-clients"]} duration={5} />
              <p>+</p>
            </h3>
            <p>Hourly view</p>
          </div>

          <div className="item-container">
            <h3>
              <CountUp end={statistics["satisfied-clients"]} duration={5} />
              <p>+</p>
            </h3>
            <p>Satisfied Clients</p>
          </div>

          <div className="item-container">
            <h3>
              <CountUp end={statistics["subscriber"]} duration={5} />
              <p>K</p>
            </h3>
            <p>Subscriber</p>
          </div>
        </div>
        <div className="sphere_big_wrapper">
          <Img src={SphereSmall}  alt="sphere-small" />
        </div>
        <div className="sphere_small_wrapper">
          <Img src={SphereBig}  alt="sphere-big" />
        </div>
      </div>
    </div>
  );
};

export default Amount;
