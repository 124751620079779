import { Img } from "react-image";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CircleLoader } from "react-spinners";
import { fetchInfluencers } from "../../services/influencersService";
import { Helmet } from "react-helmet";
import Amount from "../../сomponents/sections/Amount";
import SphereBig from "../../../src/assets/icons/benefits/sphere-medium.svg";
import SphereSmall from "../../../src/assets/icons/hero/sphere-small.svg";

const Influencer = () => {
  const [influencers, setInfluencers] = useState([]);
  const [seeAllInfluencers, setSeeAllInfluencers] = useState(false);
  const [loadingInfluencers, setLoadingInfluencers] = useState(true);
  const [errorInfluencers, setErrorInfluencers] = useState(null);

  useEffect(() => {
    setLoadingInfluencers(true);
    fetchInfluencers()
      .then((response) => {
        setInfluencers(response.data.data);
        setLoadingInfluencers(false);
      })
      .catch((error) => {
        setErrorInfluencers("An error occurred while fetching influencers");
        setLoadingInfluencers(false);
      });
  }, []);

  if (loadingInfluencers) {
    return (
      <div className="loader-container">
        <CircleLoader color={"#699158"} loading={loadingInfluencers} />
      </div>
    );
  }

  if (errorInfluencers) {
    return <div>Error: {errorInfluencers}</div>;
  }

  return (
    <>
      <Helmet>
        <title>{"CryptoWhite | Influencers" || "Title"}</title>
        <meta
          name="description"
          content={"influencers page" || "Description"}
        />
        <meta name="keywords" content={"influencers page" || "Keywords"} />
      </Helmet>

      <section className="influencers-page">
        <div className="sphere-big-container">
          <Img src={SphereBig} alt="sphere-big" />
        </div>

        <div className="sphere-small-container">
          <Img src={SphereSmall} alt="sphere-small" />
        </div>

        <div className="container">
          <div className="title-container" data-aos="zoom-in">
            <h1>Influencers</h1>
            <p>
              "Discover the power of influencers in the crypto space! Our
              curated network connects brands with leading voices, amplifying
              your message and driving engagement. Partner with us to elevate
              your crypto project through authentic and impactful
              collaborations."
            </p>
          </div>
        </div>

        <div className="influencers-page-body">
          <div className="container">
            <div className="influencers-wrapper">
              {Array.isArray(influencers) &&
                influencers
                  .slice(0, seeAllInfluencers ? influencers.length : 8)
                  .map((influencer) => (
                    <div
                      key={influencer?.id}
                      className="influencer-item"
                      data-aos="fade-up"
                    >
                      <div
                        className="influencer-item-image"
                        style={{
                          backgroundImage: `url(${influencer?.image})`,
                        }}
                      ></div>

                      <div className="influencer-item-content">
                        <p className="name">{influencer?.name}</p>
                        <div className="count">
                          <span>{influencer?.sub_count}</span>
                          <p>Subscribers</p>
                        </div>

                        <div className="buttons">
                          <Link to={`/influencer/${influencer?.id}`}>
                            Statistic
                          </Link>

                          <Link
                            to={influencer?.channel_link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Go to channel
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
            </div>

            <button
              onClick={() => setSeeAllInfluencers((prev) => !prev)}
              className="see-all-btn"
            >
              {seeAllInfluencers ? "See less" : "See all"}
            </button>
          </div>
        </div>
        <Amount />
      </section>
    </>
  );
};

export default Influencer;
