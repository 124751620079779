import { Img } from "react-image";
import { useEffect, useState } from "react";
import { CircleLoader } from "react-spinners";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation } from "swiper/modules";
import { fetchReviews } from "../../services/reviewService";

const Testimonial = () => {
  const [reviews, setReviews] = useState([]);
  const [loadingReviews, setLoadingReviews] = useState(false);
  const [errorReviews, setErrorReviews] = useState(null);

  useEffect(() => {
    setLoadingReviews(true);
    fetchReviews()
      .then((response) => {
        setReviews(response.data.data);
        setLoadingReviews(false);
      })
      .catch((error) => {
        setErrorReviews("An error occurred while fetching reviews");
        setLoadingReviews(false);
      });
  }, []);

  if (loadingReviews) {
    return (
      <div className="loader-container">
        <CircleLoader color="#699158" loading={loadingReviews} />
      </div>
    );
  }

  const generateStars = (rating) => {
    let stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(
        <i
          key={i}
          className={i < rating ? "fa-solid fa-star" : "fa-regular fa-star"}
        ></i>
      );
    }
    return stars;
  };

  return (
    <>
      <section className="section-customer">
        <div className="container">
          <div className="reviews">
            <div className="reviews-header" data-aos="zoom-in">
              <span>What Our Customers Say</span>
              <h2>
                Hear directly from our satisfied clients about their experiences
                and the impact of our marketing solutions on their projects.
                Your success is our top priority!
              </h2>
            </div>

            <div className="reviews-body" data-aos="fade-up">
              <Swiper
                className="swiper-container"
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={1}
                loop={true}
                spaceBetween={60}
                effect="EffectFade"
                coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: true,
                }}
                breakpoints={{
                  520: { slidesPerView: 1, spaceBetween: 20 },
                  768: { slidesPerView: 2, spaceBetween: 30 },
                  1024: { slidesPerView: 3, spaceBetween: 30 },
                }}
                modules={[EffectCoverflow, Navigation]}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
              >
                {reviews.map((review) => (
                  <SwiperSlide key={review?.id}>
                    <Img src={review?.user_image} alt={review?.user_name} />

                    <div className="user-info">
                      <div className="name-rate-container">
                        <h2>{review?.user_name}</h2>
                        <div className="rate">
                          {generateStars(review?.rate_count)}
                        </div>
                      </div>

                      <div
                        dangerouslySetInnerHTML={{ __html: review.content }}
                      ></div>
                    </div>
                  </SwiperSlide>
                ))}
                
                <div className="swiper-button-prev"></div>
                <div className="swiper-button-next"></div>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonial;