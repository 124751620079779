import { Img } from "react-image";
import { useEffect, useState } from "react";
import { CircleLoader } from "react-spinners";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { fetchPartners } from "../../services/partnersService";

const Partners = () => {
  const [partners, setPartners] = useState([]);
  const [loadingPartners, setLoadingPartners] = useState(false);
  const [errorPartners, setErrorPartners] = useState(null);

  useEffect(() => {
    setLoadingPartners(true);
    fetchPartners()
      .then((response) => {
        setPartners(response.data.data);
        setLoadingPartners(false);
      })
      .catch((error) => {
        setErrorPartners("An error occurred while fetching partners");
        setLoadingPartners(false);
      });
  }, []);

  if (loadingPartners) {
    return (
      <div className="loader-container">
        <CircleLoader color="#699158" loading={loadingPartners} />
      </div>
    );
  }

  return (
    <>
      <section className="section-partners">
        <div className="container-fluid">
          <Swiper
            className="my-swiper"
            effect="coverflow"
            slidesPerView={5}
            loop={true}
            spaceBetween={25}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            speed={2500}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              520: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 0,
              },
              1300: {
                slidesPerView: 5,
                spaceBetween: 0,
              },
            }}
            modules={[Autoplay, Navigation]}
          >
            {partners.map((partner) => (
              <SwiperSlide key={partner?.id}>
                <Img src={partner?.image} alt={partner?.title} />
                <p>{partner?.title}</p>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </section>
    </>
  );
};

export default Partners;