import { BrowserRouter } from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./_app";
import Header from "../src/сomponents/main/Header";
import Footer from "../src/сomponents/main/Footer";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Header />
      <App/>
      <Footer />
    </BrowserRouter>
  </React.StrictMode>
);