import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchPagesOne } from "../../services/pagesService";
import { Helmet } from "react-helmet";
import { CircleLoader } from "react-spinners";

const About = () => {
  const { pathname } = useLocation();
  const [aboutData, setPages] = useState({});
  const [loadingPages, setLoadingPages] = useState(true);
  const [errorPages, setErrorPages] = useState(null);

  useEffect(() => {
    setLoadingPages(true);
    fetchPagesOne(pathname)
      .then((response) => {
        setPages(response.data.data);
        setLoadingPages(false);
      })
      .catch((error) => {
        setErrorPages("An error occurred while fetching pages");
        setLoadingPages(false);
      });
  }, [pathname]);

  if (loadingPages) {
    return (
      <div className="loader-container">
        <CircleLoader color={"#699158"} loading={loadingPages} />
      </div>
    );
  }

  if (errorPages) {
    return <div>Error: {errorPages}</div>;
  }

  return (
    <>
      <Helmet>
        <title>{aboutData?.meta_title || "Title"}</title>
        <meta
          name="description"
          content={aboutData?.meta_description || "Description"}
        />
        <meta
          name="keywords"
          content={aboutData?.meta_keywords || "Keywords"}
        />
      </Helmet>

      <section className="section-about-page">
        <div className="container" dangerouslySetInnerHTML={{ __html: aboutData?.content }}></div>
      </section>
    </>
  );
};

export default About;