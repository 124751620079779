import { Link } from "react-router-dom";
import { Img } from "react-image";
import { useState, useEffect } from "react";
import { fetchSettings } from "../../services/settingsService";
import { CircleLoader } from "react-spinners";
import SphereBig from "../../../src/assets/icons/hero/sphere-big.svg";
import SphereSmall from "../../../src/assets/icons/hero/sphere-small.svg";
import ElementImage from "../../../src/assets/icons/hero/element.svg";
import SphereExtraSmall from "../../../src/assets/icons/hero/sphere-extra-small.svg";

const Hero = () => {
  const [settings, setSettings] = useState([]);
  const [loadingSettings, setLoadingSettings] = useState(false);
  const [errorSettings, setErrorSettings] = useState(null);

  useEffect(() => {
    setLoadingSettings(true);
    fetchSettings()
      .then((response) => {
        setSettings(response.data.data);
        setLoadingSettings(false);
      })
      .catch((error) => {
        setErrorSettings("An error occurred while fetching settings");
        setLoadingSettings(false);
      });
  }, []);

  if (loadingSettings) {
    return (
      <div className="loader-container">
        <CircleLoader color="#699158" loading={loadingSettings} />
      </div>
    );
  }

  if (errorSettings) {
    return <div>Error: {errorSettings}</div>;
  }

  return (
    <>
      <section className="section-hero">
        <div className="container">
          <div className="content" data-aos="zoom-in">
            <h1>{settings["main-title"]}</h1>
            <p>{settings["main-sub-title"]}</p>
            <Link to="/contact">Contact us</Link>
          </div>
        </div>

        <div className="sphere-big-container">
          <Img src={SphereBig} alt="sphere-big" />
        </div>

        <div className="sphere-small-container">
          <Img src={SphereSmall} alt="sphere-small" />
        </div>

        <div className="sphere-extra-small-container">
          <Img src={SphereExtraSmall} alt="sphere-extra-small" />
        </div>

        <div className="element-container">
          <Img src={ElementImage} alt="element" />
        </div>
      </section>
    </>
  );
};

export default Hero;
